<script lang="ts" setup>
import type { LinkItem } from "~/models/links";

defineProps<{ data: LinkItem }>();

const mobileMenu = useMobileMenu();
</script>

<template>
  <NuxtLink
    v-if="data.href && data.click"
    :to="data.href"
    v-bind="$attrs"
    class="hover:text-primary cursor-pointer"
    :class="{ 'font-bold': data.highlight }"
    @click.prevent="data.click as any"
  >
    <i v-if="data.iconName" :class="`pe-7s-${data.iconName}`" class="pe-lg" />
    {{ data.label }}
    <slot name="content-after" />
  </NuxtLink>

  <NuxtLink
    v-else-if="data.href"
    :to="data.href"
    :target="data.target"
    v-bind="$attrs"
    class="hover:text-primary"
    :class="{ 'font-bold': data.highlight }"
    @click="mobileMenu.isOpen = false"
  >
    <i v-if="data.iconName" :class="`pe-7s-${data.iconName}`" class="pe-lg" />
    {{ data.label }}
    <slot name="content-after" />
  </NuxtLink>

  <span
    v-else
    v-bind="$attrs"
    :class="{ 'font-bold': data.highlight }"
  >{{ data.label }}</span>
</template>

<style scoped>

</style>
